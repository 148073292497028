<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="add-address-main box-flex">
      <div class="review-main text-capitalize position-relative box-flex__wrapper">
        <AddAddress></AddAddress>
        </div>
    </section>
    <Footer></Footer>
  </div>
</template>


<script>
import NavBar from "../components/NavBar.vue";
import Footer from '../components/Footer.vue';
import AddAddress from '../components/AddAddress.vue';

export default {
  name: "Addaddress",
  components: {NavBar, Footer, AddAddress},
  mounted() {
    setTimeout(() => {      
      if(!this.$auth.user){
        this.$router.push({ name: "sign-in" });
      }
    }, 500);
  },
  methods: {

  },
};
</script>